import {
  parse,
  isBefore,
  isAfter,
} from "https://cdn.skypack.dev/pin/date-fns@v2.27.0-kSwHFMsWRtnuspqyJxns/mode=imports,min/optimized/date-fns.js";

var PageAlert = function (opts) {
  this.opts = opts;
  if (
    this.opts.AlertActive &&
    this.opts.AlertStartDate &&
    this.opts.AlertEndDate
  ) {
    const dateFormat = "yyyy'-'MM'-'dd'T'HH:mm:ss' 'X";
    const startDate = parse(this.opts.AlertStartDate, dateFormat, new Date());
    const endDate = parse(this.opts.AlertEndDate, dateFormat, new Date());
    const now = new Date();
    if (
      startDate == now ||
      (isBefore(startDate, now) && isAfter(endDate, now))
    ) {
      this.opts.AlertActive = true;
    } else {
      this.opts.AlertActive = false;
    }
  }
  this.page_alert_wrapper = document.querySelector(".page-alert-wrapper");
  this.page_alert = document.querySelector(".page-alert-message");
  this.alert_states = { NotViewed: 0, Viewed: 1 };
  this.alert_dismiss_click = this.hideAlert;
  this.alert_dismiss_key = function (evt) {
    if (evt.which == 27) this.hideAlert();
  };

  // make sure state is set to NotViewed for pages without an active alert
  if (!this.opts.AlertActive) this.setState(this.alert_states.NotViewed);

  if (
    this.opts.AlertActive &&
    (!this.hasBeenViewed() || this.opts.AlwaysShow)
  ) {
    if (parseFloat(this.opts.Delay)) {
      var pa = this;
      setTimeout(function () {
        pa.showAlert();
      }, this.opts.Delay * 1000);
    } else {
      this.showAlert();
    }
  }
};
PageAlert.prototype.getState = function () {
  var nameEQ = "alert_state=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
PageAlert.prototype.hasBeenViewed = function () {
  return this.getState() == this.alert_states.Viewed ? true : false;
};
PageAlert.prototype.setState = function (state) {
  var date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
  document.cookie =
    "alert_state=" + state + "; expires=" + date.toGMTString() + "; path=/";
};
PageAlert.prototype.hideAlert = function () {
  this.page_alert.setAttribute("style", "display: none");
  this.page_alert_wrapper.setAttribute("style", "display: none");

  document.body.removeEventListener("keyup", this.hideAlert);
};
PageAlert.prototype.showAlert = function () {
  this.setState(this.alert_states.Viewed);
  var pageAlert = this.page_alert_wrapper;
  var alertControls = document.createElement("div");
  alertControls.className = "controls";
  var alertButton = document.createElement("input");
  alertButton.id = "page-alert-dismiss";
  alertButton.value = "Close";
  alertButton.type = "submit";

  alertControls.append(alertButton);

  this.page_alert.append(alertControls);
  document.body.prepend(this.page_alert_wrapper);
  this.page_alert_wrapper.setAttribute("style", "display: block;");
  this.page_alert.setAttribute("style", "display: block;");
  document.body.prepend(this.page_alert);

  var dismissButton = document.getElementById("page-alert-dismiss");
  if (dismissButton != null) {
    dismissButton.addEventListener("click", this.hideAlert.bind(this));
  }
  document.body.addEventListener("keyup", this.alert_dismiss_key.bind(this));
};
PageAlert.prototype.debug = function () {
  if (console != undefined) {
    console.log("IsActive: " + this.opts.AlertActive);
    console.log("HasBeenViewed: " + this.hasBeenViewed());
    console.log("AlwaysShow: " + this.opts.AlwaysShow);
    console.log("CurrentState: " + this.getState());
  }
};

window.addEventListener("load", function () {
  new PageAlert(page_alert_options);
});
